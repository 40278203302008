@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@100;400;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;300;400;600;800&display=swap');
:root {
  --color-primary: #408fda;
  --color-secondary: #333;
  --color-gray-light: #dfdfdf;
  --color-gray-lighter: #f1f1f1;
  --color-bg-dark: #2e2e2e;
  --color-light: rgba(255, 255, 255, 1);
  --color-gray-dark: #666;
  --color-alert: rgb(238, 44, 44);
  --color-alert-light: rgb(255, 200, 200);
  --color-success: rgb(74, 160, 78);
  --color-success-light: rgb(194, 255, 197);
  --color-warning: rgb(255, 234, 116);
  --color-hover-black-light: rgba(0, 0, 0, .2);
  --color-hover-black-dark: rgba(0, 0, 0, .8);
  --color-hover-light: rgba(255, 255, 255, .2);
}
@keyframes Loading-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}
html, body, div, span, object, iframe, h1, h2, h3, h4, h5, h6,
p, blockquote, pre, a, abbr, address, cite, code, del,
dfn, em, img, ins, kbd, q, s, samp, small, strong, sub,
sup, var, b, u, i, dl, dt, dd, ol, ul, li, fieldset, form,
label, legend, table, caption, tbody, tfoot, thead, tr, th, td {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  background: transparent;
}
*, *:before, *:after {
  box-sizing: border-box;
}
body {
  scrollbar-width: thin;
  scrollbar-color: var(--color-primary) var(--color-secondary);
}
::-webkit-scrollbar {
  width: 20px;
}
::-webkit-scrollbar-track {
  background: var(--color-secondary);
}
::-webkit-scrollbar-thumb {
  background-color: var(--color-primary);
  border-radius: 20px;
  border: 3px solid var(--color-secondary);
}
html { /* end body */
}
html body {
    color: var(--color-secondary);
    background-color: var(--color-light);
    line-height: 1;
    text-size-adjust: 100%;
    font-family: 'Montserrat', sans-serif;
  }
/* end html */
section {
  background-color: var(--color-light);
  width: calc(100% - 20px);
  margin: 0 auto;
}
section.container {
    padding-top: 80px;
  }
.full-height {
  height: 100%;
}
.full-height {
  height: 100%;
}
ol, ul {
  list-style: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
}
table th {
    font-weight:normal;
  }
label, button, input[type="submit"] {
  cursor:pointer;
}
a, a:visited, a:active, a:focus {
  text-decoration: none;
  color:var(--color-secondary);
  outline:none;
}
a:hover {
    text-decoration: none;
    color:#000;
  }
input, textarea, select {
  width: 100%;
  outline-color: var(--color-primary);
}
input:focus, textarea:focus, select:focus {
    background-color: rgba(64, 144, 218, .2);
  }
input::placeholder {
    color: var(--color-primary);
    font-size: 12px;
  }
input[type='radio'],
  input[type='checkbox'] {
    width: auto;
    vertical-align: middle;
    margin: 0 3px 0 10px;
  }
input:disabled {
    background-color: var(--color-gray-light);
    color: var(--color-gray-dark);
  }
textarea {
  height:100px;
  margin-bottom: 20px;
  padding: 15px;
  width:100%;
}
textarea::-webkit-input-placeholder {
    color:var(--color-gray-dark);
  }
textarea:-moz-placeholder {
    color:var(--color-gray-dark);
    opacity:1;
  }
textarea::-moz-placeholder {
    color:var(--color-gray-dark);
    opacity:1;
  }
textarea:-ms-input-placeholder {
    color:var(--color-gray-dark);
  }
select{
  cursor: pointer;
  appearance: none;
  text-indent: 0.01px;
  text-overflow: '';
}
select::-webkit-input-placeholder {
    color:var(--color-gray-light);
  }
select:-moz-placeholder {
    color:var(--color-gray-light);
    opacity:1;
  }
select::-moz-placeholder {
    color:var(--color-gray-light);
    opacity:1;
  }
select:-ms-input-placeholder {
    color:var(--color-gray-light);
  }
select::-ms-expand {
    display: none;
  }
select:disabled {
    background-color: var(--color-gray-light);
    color: var(--color-gray-dark);
  }
select option {
    padding: 2px;
  }
span.clear {
    display:block;
    clear:both;
    height: 0;
  }
span.space{
    display:block;
    margin:10px 0;
    height:1px; clear:both;
  }
.transition, .transition:hover {
  transition:all 0.3s ease-in ;
}
::selection {
  background: var(--color-secondary);
  color: #eee;
}
.clear:after{
  content:'';
  display:block;
  clear:both;
}
.pointer {
  cursor: pointer;
}
.align-left {
  text-align:left;
}
.align-right {
  text-align:right;
}
.align-center {
  text-align:center;
}
.align-justify {
  text-align:justify;
}
.float-right {
  float: right !important;
}
.float-left {
  float: left !important;
}
.center{
  display:block !important;
  margin-left:auto !important;
  margin-right:auto !important;
}
.margin-top {
  margin-top:2rem;
}
.comum-color{
  color: var(--color-gray-dark);
  text-shadow: 0 0 1px rgba(0,0,0, 0.2);
}
.relative{
  position:relative;
}
.button {
  background: var(--color-primary);
  border: none;
  color: var(--color-light);
  font-family: 'Montserrat', sans-serif;
  font-size: 1rem;
  display: inline-block;
  margin: 1rem 0;
  padding: 10px 20px;
}
.button.full-width {
    width: 100%;
  }
.button.cancel {
    background: var(--color-gray-dark);
  }
.button i {
    margin: 0 5px;
  }
.button.loading {
    box-shadow: 50px 50px rgb(0 0 0 / 40%) inset;
    font-size: 27px;
    padding: 5px;
  }
.button.loading i {
      animation: Loading-spin infinite 1.5s linear;
    }
.button:hover {
    box-shadow: 0 0 300px var(--color-hover-black-light) inset;
  }
.button[disabled] {
    background-color: var(--color-gray-light);
    color: var(--color-gray-dark);
    cursor: not-allowed;
    box-shadow: none;
  }
.format-style h1, .format-style h2, .format-style h3, .format-style h4, .format-style h5, .format-style h6 {
    line-height: 70px;
    font-weight:bold;
  }
.format-style h1 {
    font-size: 45px;
  }
.format-style h2 {
    font-size: 38px;
  }
.format-style h3 {
    font-size: 32px;
  }
.format-style h4 {
    font-size: 27px;
  }
.format-style h5 {
    font-size: 24px;
  }
.format-style h6 {
    font-size: 21px;
  }
.format-style hr {
    margin:30px 0;
  }
.format-style a {
    text-decoration:underline;
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
  }
.format-style p, .format-style dd {
    line-height:25px;
    margin-bottom:25px;
    font-size:14px;
  }
.format-style dt {
    font-size: 18px;
    line-height: 25px;
    font-weight:bold;
    font-family: 'Roboto', sans-serif;
  }
.format-style ul, .format-style ol {
    margin-left:20px;
  }
.format-style ul li {
      list-style:disc;
      line-height:20px;
    }
.format-style ol li {
      list-style:decimal;
      line-height:20px;
    }
.format-style fieldset {
    border: 1px solid rgba(0, 0, 0, 0.1);
    margin: 0 0 24px;
    padding: 11px 12px;
  }
.format-style label {
    margin-bottom: 15px;
    display: block;
  }
.format-style input[type="radio"],
  .format-style input[type="checkbox"] {
    width:auto;
    height:auto
  }
.format-style input, .format-style textarea, .format-style select {
    margin-top: 5px;
  }
.format-style button, .format-style input.button {
    padding:10px 20px;
    text-transform:uppercase;
    border: 1px solid rgba(255,255,255,0.4);
    cursor:pointer;
    outline:none;
    border: 1px solid rgba(0,0,0,0.4);
    height: auto;
  }
.format-style table td, .format-style table th {
      border:1px solid rgba(0, 0, 0, 0.1);
      padding:10px;
    }
.format-style table td {
      font-size:14px;
    }
.format-style table th {
      text-transform:uppercase;
      font-family: 'Roboto', sans-serif;
      text-align:left;
    }
.format-style pre{
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    margin-bottom: 24px;
    max-width: 100%;
    overflow: auto;
    padding: 10px;
    background:#fdfdfd;
    white-space: pre;
    white-space: pre-wrap;
    word-wrap: break-word;
  }
.format-style blockquote{
    position: relative;
    padding: 30px 64px 10px;
    font-size: 34px;
    color: #D7D6D6;
    font-weight: bold;
    font-style: italic;
    font-family: 'Roboto', sans-serif;
    text-shadow: 4px 4px rgba(197, 197, 197, 0.07);
  }
.format-style blockquote:before{
      content:'\f10d';
      position:absolute;
      top:11px;
      left:0;
      font-size:60px;
      font-family:FontAwesome;
      transform:rotate(11deg);
    }
.format-style blockquote:after{
      content:'\f10e';
      position:absolute;
      bottom:11px;
      right:0;
      font-size:60px;
      font-family:FontAwesome;
      transform:rotate(11deg);
      }
.format-style blockquote * {
      color: #D7D6D6;
      line-height: 40px;
    }
.format-style strong {
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
  }
/* end format-style */
.App {
  text-align: center;
}
.App-logo {
      animation: App-logo-spin infinite 20s linear;
      height: 80px;
    }
.App-header {
      background-color: var(--color-default-222);
      height: 150px;
      padding: 20px;
      color: #fff;
    }
.App-title {
      font-size: 1.5em;
    }
.App-intro {
      font-size: large; 
    }
@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}
nav ul li {
      display: inline-block;
      margin: 10px 10px 2px;
    }
.grid {
  padding: 40px;
}
.grid.container {
    display: grid;
    grid-template-columns: 100px 100px 100px;
    grid-template-rows: 100px 100px 100px;
    grid-gap: 1rem;
  }
.grid.container > div {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 3px;
      background-color: #557cf2;
      font: 50px Helvetica, san-serif;
      color: #fff;
    }
.grid.container > div.item2 {
       grid-column-start: 2;
       grid-column-end: 4;
      }
.grid.container > div.item3 {
        grid-row-start: 2;
        grid-row-end: 4;
      }
.grid.container > div.item6 {
        grid-column-start: 2;
        grid-column-end: 4;
      }
.grid.mosaico {
    display: grid;
    grid-template-columns: 33.3333% 33.3333% 33.3333%;
    grid-template-rows: 200px 200px 200px;
    grid-gap: 1px;
  }
.grid.mosaico > div {
      display: flex;
      justify-content: center;
      align-items: center;
      font: 50px Helvetica, san-serif;
      color: #fff;
    }
.grid.mosaico > div img {
        width: 100%;
        object-fit: cover;
        object-position: center;
        height: 100%;
      }
.grid.mosaico > div.item2 {
       grid-column-start: 2;
       grid-column-end: 4;
      }
.grid.mosaico > div.item3 {
        grid-row-start: 2;
        grid-row-end: 4;
      }
.grid.mosaico > div.item6 {
        grid-column-start: 2;
        grid-column-end: 4;
      }
